import React, { useEffect, useRef } from 'react';
import { getEmbedVideoId } from '@utils/DataMappingUtils';

interface IProps {
    videoUrl: string;
    height?: string;
    className?: string;
    title?: string;
    onPlay?: () => void;
    onPause?: () => void;
    onEnd?: () => void;
}

export default function (props: IProps) {
    const { videoUrl, height = '300px', className, title, onPlay, onPause, onEnd } = props;
    const style = ['embed-responsive embed-responsive-21by9', className].join(' ');
    const iframeRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        let player: any;
        
        const initPlayer = () => {
            if (!iframeRef.current) return;

            player = new (window as any).YT.Player(iframeRef.current, {
                events: {
                    onStateChange: (event: any) => {
                        switch (event.data) {
                            case (window as any).YT.PlayerState.PLAYING:
                                onPlay?.();
                                break;
                            case (window as any).YT.PlayerState.PAUSED:
                                onPause?.();
                                break;
                            case (window as any).YT.PlayerState.ENDED:
                                onEnd?.();
                                break;
                        }
                    }
                }
            });
        };

        // Load YouTube API
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);

        (window as any).onYouTubeIframeAPIReady = initPlayer;

        return () => {
            if (player) {
                player.destroy();
            }
        };
    }, [onPlay, onPause, onEnd]);

    return (
        <div className={style}>
            <iframe
                ref={iframeRef}
                allowFullScreen
                className="embed-responsive-item"
                frameBorder="0"
                height={height}
                id="ytplayer"
                src={`${getEmbedVideoId(videoUrl)}?enablejsapi=1`}
                title={title}
                width="100%"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
        </div>
    );
}