import React, { lazy, Suspense } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";

import { BrowserScrollService } from "@utils/BrowserScrollService";
import { StorageKeys } from "@utils/LocalStorage";
import { getLoggedInUserId, handleAuthFlow } from "@utils/UserUtils";

import { ProfileModel } from "@common/domain/models/Profile";
import { isAuthenticatedSelector } from "@common/redux/auth/selectors";
import { getProfileByUserIdSelector } from "@common/redux/profile/selectors";

import { guestUserLangCode } from "@translations/AppLanguage";
import { i18n } from "@translations/i18n";

import ErrorBoundary from "@components/molecules/ErrorBoundary";
import Spinner from "@components/old/atoms/Spinner";

import Navigator from "@navigator/index";
import { NavigationUrl, URLs } from "@navigator/NavigationUrl";
import { Routes } from "@navigator/Routes";

import { platform } from "@constants/config";
import { LocalizedRouter } from "@router/components/LocalizedRouter";
import { LocalizedSwitch } from "@router/components/LocalizedSwitch";
import { RoutePrivate } from "@router/RoutePrivate";
import RoutePublic from "@router/RoutePublic";
import { LanguageService } from "@webServices/LanguageService";
import { ConnectedRouter } from "connected-react-router";
import { RouteAuthenticatedWithoutMenu } from "./RouteAuthenticatedWithoutMenu";


const SelfRegisterContainer = lazy(() => import(/* webpackChunkName: "SelfRegisterContainer" */ "@containers/Auth/SelfRegisterContainer"));
const ResetPassword = lazy(() =>
  import(/* webpackChunkName: "ResetPassword" */ "@containers/Auth/ResetPasswordContainer")
);
const ChangePassword = lazy(() =>
  import(/* webpackChunkName: "ChangePassword" */ "@containers/Auth/CreatePasswordContainer")
);
const ForgotPassword = lazy(() =>
  import(/* webpackChunkName: "ForgotPassword" */ "@containers/Auth/ForgotPasswordContainer")
);
const Logout = lazy(() => import(/* webpackChunkName: "Logout" */ "@containers/Auth/LogoutContainer"));
const Login = lazy(() => import(/* webpackChunkName: "Login" */ "@containers/Auth/LoginContainer"));
const RegisterUser = lazy(() =>
  import(/* webpackChunkName: "RegisterUserContainer" */"@containers/Auth/RegisterUserContainer"));
const TnC = lazy(() => import(/* webpackChunkName: "TnC" */"@containers/Auth/TnCContainer"));
const GuestDashboard = lazy(() =>
  import(/* webpackChunkName: "GuestDashboard" */"@presentation/GuestView/GuestPage")
);
const GuestView = lazy(() => import(/* webpackChunkName: "GuestView" */"@containers/Guest/GuestViewContainer"));
const RefreshCookies = lazy(() =>
  import(/* webpackChunkName: "RefreshCookiesContainer" */"@containers/RefreshCookies/RefreshCookiesContainer")
);
const Home = lazy(() => import(/* webpackChunkName: "Home" */"@presentation/Home"));
const SsoLogin = lazy(() => import(/* webpackChunkName: "SsoLoginContainer" */"@containers/SsoLogin/SsoLoginContainer"));
const UserVerificationContainer = lazy(() => import(/* webpackChunkName: "UserVerificationContainer" */"@containers/Auth/UserVerificationContainer"));
const AuthenticateContainer = lazy(() => import(/* webpackChunkName: "AuthenticateContainer" */"@containers/Auth/AuthenticateContainer"));
const ProfileContainer = lazy(() => import(/* webpackChunkName: "ProfileContainer" */'@containers/Profile/ProfileContainer'));


//career advisor
const CareerAdvisor = lazy(() => import(/* webpackChunkName: "CareerAdvisor" */"@presentation/CareerAdvisor"));
const careerAdvisorJobDescription = lazy(() => import(/* webpackChunkName: "CareerAdvisor" */"@presentation/CareerAdvisor/JobRoleDescription"));
const careerAdvisorQuestionnairesRatings = lazy(() => import(/* webpackChunkName: "CareerAdvisor" */"@presentation/CareerAdvisor/QuestionnairesRatings"));
const questionnaireByAttributeKeyContainer = lazy(() => import(/* webpackChunkName: "CareerAdvisor" */"@containers/QuestionnaireContainer/QuestionnaireByAttributeKeyContainer"));
const questionnaireByAttributeGroupKeyContainer = lazy(() => import(/* webpackChunkName: "CareerAdvisor" */"@containers/QuestionnaireContainer/QuestionnaireByAttributeGroupKeyContainer"));
const questionnaireSummaryContainer = lazy(() => import(/* webpackChunkName: "CareerAdvisor" */"@containers/QuestionnaireContainer/QuestionnaireSummaryContainer"));

const BrowsedRoles = lazy(() => import(/* webpackChunkName: "BrowsedRoles" */"@presentation/CareerAdvisor/BrowseRoles"));
const Favourites = lazy(() => import(/* webpackChunkName: "Favourites" */"@presentation/CareerAdvisor/Favourites"));
const MatchedRoles = lazy(() => import(/* webpackChunkName: "MatchedRoles" */"@presentation/CareerAdvisor/MatchedRoles"));
const ShareReport = lazy(() => import(/* webpackChunkName: "ShareReport" */"@presentation/CareerAdvisor/ShareReport"));
const PssciveCourses = lazy(() => import(/* webpackChunkName: "PssciveCourses" */"@presentation/CareerAdvisor/PssciveCourses"));
const Passions = lazy(() => import(/* webpackChunkName: "Passions" */"@presentation/CareerAdvisor/Passions"));
const About = lazy(() => import(/* webpackChunkName: "About" */"@presentation/About"));


interface IProps {
  primaryLanguage?: any;
  isAuthenticated: boolean;
  location: any;
  loggedInUserId: string;
  userProfile: ProfileModel;
}

interface IState { }

const setParentRef = (pRef: any) => {
  BrowserScrollService.init(pRef);
};

export const mapStateToProps = (state: any) => {

  const userId = getLoggedInUserId();

  return {
    loggedInUserId: getLoggedInUserId(),
    isAuthenticated: isAuthenticatedSelector(state),
    location: state.router.location,
    userProfile: getProfileByUserIdSelector(state, userId),
  };
};

export const mapDispatchToProps = () => {
  return {};
};

export class WFRouter extends React.Component<IProps, IState> {
  scrollRef: any;

  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
  }

  public componentDidUpdate?(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any
  ): void {


    if (prevProps.isAuthenticated && !this.props.isAuthenticated) {
      const guestLang = guestUserLangCode();
      LanguageService.changeLanguage(guestLang);
      Navigator.replace(NavigationUrl.generate(URLs.guest));
    }

    if (this.props.loggedInUserId && platform.CAREERS) {

      const { userProfile } = this.props;

      const prevUserProfile = prevProps.userProfile;

      const prevPath = prevProps?.location?.pathname;
      const currentPath = this.props?.location?.pathname;

      if ((userProfile && !prevUserProfile) || (userProfile && (prevPath !== currentPath))) {
        // check if logged user profile details are complete
        handleAuthFlow(this.props?.location?.pathname, userProfile);
      }
    }
  }



  public render() {
    const { location, isAuthenticated } = this.props;

    const isWebViewValue = !!(window.localStorage.getItem(StorageKeys.IS_WEBVIEW));

    let RouteWrapper: any;
    if (isAuthenticated || isWebViewValue) {
      RouteWrapper = RoutePrivate;
    } else {
      RouteWrapper = RoutePublic;
    }

    const authenticate = isAuthenticated || isWebViewValue;

    const pathToHome = () => (
      <Redirect to={NavigationUrl.generate(URLs.matchedRoles)} />
    );

    return (
      <div className="app-container">
        <LocalizedRouter RouterComponent={ConnectedRouter} defaultLanguage={i18n.language}>
          <ErrorBoundary>
            <Suspense fallback={<Spinner showFullPage />}>
              <LocalizedSwitch>
                {/* <RoutePrivate
                  exact
                  path={Routes.Home.home}
                  component={Home}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.Home.default}
                  component={Home}
                  isAuthenticated={authenticate}
                  location={location}
                /> */}
                <Route
                  exact
                  path={Routes.Auth.ssoLogin}
                  render={(props) => <SsoLogin {...props} />}
                />
                <RoutePublic
                  exact
                  path={Routes.Auth.guest}
                  component={GuestDashboard}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.Profile.profileDetail}
                  component={ProfileContainer}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <Route
                  exact
                  path={Routes.General.tnc}
                  render={(props) => <TnC {...props} />}
                />
                <Route
                  exact
                  path={Routes.General.refreshCookies}
                  render={(props) => <RefreshCookies {...props} />}
                />

                <RoutePublic
                  exact
                  path={Routes.Auth.register}
                  component={(props) => <SelfRegisterContainer {...props} />}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePublic
                  exact
                  path={Routes.Auth.login}
                  component={(props) => <Login {...props} />}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <Route
                  exact
                  path={Routes.Profile.createProfile}
                  render={(props) => <RegisterUser {...props} />}
                />
                <Route
                  exact
                  path={Routes.Auth.resetPassword}
                  render={(props) => <ResetPassword {...props} />}
                />
                <Route
                  exact
                  path={Routes.Auth.changePassword}
                  render={(props) => <ChangePassword {...props} />}
                />
                <Route
                  exact
                  path={Routes.Auth.forgotPassword}
                  render={(props) => <ForgotPassword {...props} />}
                />
                <Route
                  exact
                  path={Routes.Auth.logout}
                  render={(props) => <Logout {...props} />}
                />
                <Route
                  exact
                  path={Routes.Privacy.tnc}
                  render={(props) => <TnC {...props} />}
                />
                <Route
                  exact
                  path={Routes.Auth.userVerification}
                  render={(props) => <UserVerificationContainer {...props} />}
                />
                <Route
                  exact
                  path={Routes.Auth.authenticate}
                  component={AuthenticateContainer}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.CareerAdvisor.careerAdvisor}
                  component={CareerAdvisor}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.CareerAdvisor.careerAdvisorJobDescriptionDeatil}
                  component={careerAdvisorJobDescription}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.CareerAdvisor.careerAdvisorQuestionnairesRatingsDetail}
                  component={careerAdvisorQuestionnairesRatings}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.CareerAdvisor.questionnaireByAttributeGroupKey}
                  component={questionnaireByAttributeGroupKeyContainer}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.CareerAdvisor.questionnaireSummaryDetail}
                  component={questionnaireSummaryContainer}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.CareerAdvisor.questionnaireByAttributeKey}
                  component={questionnaireByAttributeKeyContainer}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.CareerAdvisor.matchedRoles}
                  component={MatchedRoles}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.CareerAdvisor.browseRoles}
                  component={BrowsedRoles}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.CareerAdvisor.favourites}
                  component={Favourites}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RouteWrapper
                  exact
                  path={Routes.CareerAdvisor.shareReport}
                  component={ShareReport}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.CareerAdvisor.pssciveCourses}
                  component={PssciveCourses}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RouteAuthenticatedWithoutMenu
                  exact
                  path={Routes.CareerAdvisor.passions}
                  component={Passions}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.About.about}
                  component={About}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <Route exact path={Routes.Home.default} render={pathToHome} />
                <Route path={Routes.Home.home} render={pathToHome} />
                <Route path={Routes.Home.lang} render={pathToHome} />
                <Redirect to={Routes.General.notFound} />
              </LocalizedSwitch>
            </Suspense>
          </ErrorBoundary>
        </LocalizedRouter>
      </div>
    );

  }

  public componentDidMount = () => {
    const reactNode = this.scrollRef.current;
    setParentRef(reactNode);
  };
}

// @ts-ignore
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(WFRouter));