import Config from '@config/index';
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";
import firebase from "firebase/app";

export const enum SCREEN_NAME {
  HOME = "HomeScreen",
  CALENDAR = "CalendarScreen",
  CHAT = "ChatScreen",
  LOGIN = "LoginScreen",
  REGISTER = "RegisterScreen",
  CREATE_PROFILE = "CreateProfileScreen",
  FORGOT_PASSWORD = "ForgotPasswordScreen",
  CREATE_PASSWORD = "CreatePasswordScreen",
  GUEST_HOME = "GuestHomeScreen",
  CREATE_BATCH = "CreateBatchScreen",
  USER_VERIFICATION = "UserVerificationScreen",
  USER_SUPPORT = "UserSupportScreen",
  ALL_BATCHES = "AllBatchesScreen",
  USER_PROFILE = "UserProfileScreen",
  EDIT_BATCH = "EditBatchScreen",
  MEETING_DETAIL = "MeetingDetailScreen",
  RESET_PASSWORD = "ResetPasswordScreen",
  INVITE_STUDENT = "InviteStudentScreen",
  MANAGE_STUDENT = "ManageStudentScreen",
  BATCH_DETAIL = "BatchDetailScreen",
  REPORT = "ReportScreen",
  BATCH_LIST = "BatchListScreen",
  COURSE_DETAIL = "CourseDetailScreen",
  TOOLKIT = "toolkit",
  PROGRAM_DETAIL = "ProgramDetailScreen",
  EVENT_DETAIL = "EventDetailScreen",
  MATCHED_ROLES = "MatchedRolesScreen",
}

export const enum EVENT_NAME {
  CA_COL_PREF = "ca_col_pref",
  CA_SIGN_UP = 'ca_sign_up',
  CA_QNS_START = 'ca_qns_start',
  CA_QNS_SUBMIT = 'ca_qns_submit',
  CA_PASS_START = 'ca_pass_start',
  CA_PASS_SUBMIT = 'ca_pass_submit',
  CA_ROLE_VIEW = 'ca_role_view',
  CA_ROLE_FAV = 'ca_role_fav',
  CA_VOCATIONAL = 'ca_vocational',
  CA_ROLE_FILTER = 'ca_role_filter',
  CA_FAV_REORDER = 'ca_fav_reorder',
  CA_FAV_FILTER = 'ca_fav_filter',
  CA_ROLE_DIL_VIEW = 'ca_role_dil_view',
  CA_LOG_IN = 'ca_log_in',
  CA_ROLE_SEARCH = 'ca_role_search',
}
export class AnalyticsService {

  public static init() {
    firebase.initializeApp(Config.FIREBASE_WEB_CONFIG);
    firebase.analytics().setAnalyticsCollectionEnabled(true);
  }

  public static pageViewAnalytics = (screenName: string) => {
    firebase.analytics().setCurrentScreen(screenName);
  }

  public static setUserId = (uuid: string) => {
    firebase.analytics().setUserId(uuid);
  }

  public static setUserProperties = (propsDict: object) => {
    firebase.analytics().setUserProperties(propsDict);
  }

  public static logEvent = (eventName: string, params?: any) => {
    firebase.analytics().logEvent(eventName, params);
  }

  public static getPageViewEvent = () => {
    return firebase.analytics.EventName.PAGE_VIEW;
  }

}
